import React from "react"
import "../css/harvey.css"

export const FeaturesTable = ({ name, features }) => {
  const harvey = (value = 0, active = false) => {
    if (active) {
      if (value === 0) {
        return <div class="harvey"></div>
      }
      if (value === 0.25) {
        return <div class="harvey quarters quarter active"></div>
      }
      if (value === 0.5) {
        return <div class="harvey quarters half active"></div>
      }
      if (value === 0.75) {
        return <div class="harvey quarters three-quarters active"></div>
      }
      if (value === 1) {
        return <div class="harvey quarters full active"></div>
      }
      if (value === "N/A") {
        return "N/A"
      }
    } else {
      if (value === 0) {
        return <div class="harvey"></div>
      }
      if (value === 0.25) {
        return <div class="harvey quarters quarter"></div>
      }
      if (value === 0.5) {
        return <div class="harvey quarters half"></div>
      }
      if (value === 0.75) {
        return <div class="harvey quarters three-quarters"></div>
      }
      if (value === 1) {
        return <div class="harvey quarters full"></div>
      }

      if (value === "N/A") {
        return "N/A"
      }
    }

    return <div class="harvey"></div>
  }

  let description = []
  description["Pricing"] =
    "Optimization and automation of domestic and international pricing"
  description["Go-To-Market"] =
    "Integrated product management from launch to end of life"
  description["Collaboration"] =
    "Collaboration features for approvals, sharing, and analytics"
  description["Competitive Intelligence"] =
    "Real-time insights into competitor pricing dynamics"
  description["Promotion Planning"] =
    "Price promotion planning and volume uplift estimation"
  description["Bundle Planning"] =
    "Software and hardware bundle planning and P&L impact estimation"
  description["Reporting"] =
    "Readable and insightful reporting for diverse audiences"
  description["User Friendliness"] = "Fast and intuitive interface on mobile and desktop"
  description["Compliance Operations"] = "Integrated SOX controls and tax filing"

  return (
    <table className="text-sm mx-auto text-gray-700 w-full">
      <thead className="text-gray-800 font-bold text-base">
        <th></th>
        <th className="text-center px-8 pt-4 bg-green-50  text-green-800 rounded-r-md rounded-l-md w-1/5">
          PriceList
        </th>
        <th className="text-center px-8 pt-4 text-gray-500 w-1/5">{name}</th>
      </thead>
      <tbody className="divide-y divide-gray-100">
        {features &&
          features.map(({ label, pricelist, company }) => (
            <tr>
              <td className="text-left py-1 px-4">
                <span className="font-bold">{label}</span>
                <br />
                <span className="text-gray-400" style={{ fontSize: "0.75rem" }}>
                  {description[label]}
                </span>
              </td>
              <td className="text-center bg-green-50">
                {harvey(pricelist, true)}
              </td>
              <td className="text-center">{harvey(company)}</td>
            </tr>
          ))}
      </tbody>
      <tbody>
        <tr>
          <td></td>
          <td className="bg-green-50 rounded-b-md h-4"></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  )
}

export default FeaturesTable
