import React from "react"
import { Link } from "gatsby"
import { PageHeading } from "../components/PageHeading"
import Layout from "../components/Layout"
import CallToActionPageBottom from "../components/CallToActionPageBottom"
import { FeaturesTable } from "../components/FeaturesTable"
import { Button } from "../components/Button"
import tw from "twin.macro"
import { ChevronLeft } from "heroicons-react"
const P = tw.p`max-w-prose py-2 text-gray-600 leading-8`

export default ({
  pageContext: {
    company: { name, snippet, features },
  },
}) => {
  return (
    <>
      <Layout
        title={`Pricelist and ${name} | Pricelist`}
        keywords={`Pricelist and ${name} features comparison`}
      >
        <div class="relative px-4 sm:px-6 lg:px-8">
          <div class="text-lg max-w-prose mx-auto">
            <PageHeading
              title={`Pricelist and ${name}`}
              subtitle="Feature comparison"
            />

            <P>
              Pricelist provides analytical and collaborative features to
              help your company bring products to market and manage their
              pricing throughout their life cycle. It automatically provides
              up-to-date taxes, duties, exchange rates, and competitive
              intelligence that teams need to price their products.
            </P>

            {!Array.isArray(snippet) && <P>{snippet}</P>}

            {Array.isArray(snippet) &&
              snippet.map(paragraph => <P>{paragraph}</P>)}

            <h3 className="py-4 font-bold text-gray-800">
              Compare features between Pricelist and {name}
            </h3>
            <FeaturesTable features={features} name={name} />
          </div>

          <CallToActionPageBottom cta="Get in touch">
            <div>Do you need a {name} integration?</div>
            <div>
              Reach out to our platform team for roadmap or see what{" "}
              <Link to="/integrations" className="underline hover:no-underline">
                integrations
              </Link>{" "}
              Pricelists supports out of the box.
            </div>
          </CallToActionPageBottom>

          <Link
            to="/product/compare"
            className="text-indigo-800 underline hover:no-underline max-w-prose mx-auto block -mt-16 flex"
          >
            <svg
              className="h-5 w-5"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <ChevronLeft />
            </svg>{" "}
            Go back to all comparisons
          </Link>
        </div>
      </Layout>
    </>
  )
}
